import React from 'react';
import useScroll from '../../hooks/useScroll';

const Main = () => {
  return (
    <div>
      Coming soon
      {/* <div className='w-full flex justify-center items-center min-h-screen'>
        <h1 {...useScroll('', 'up', 1, 0)}>
          담배도 이젠 24h 무인으로 판매하세요.
        </h1>
      </div>

      <div className='w-full max-w-[120rem] my-0 mx-auto'>
        <div {...useScroll('', 'up', 1, 0)} className='w-full flex gap-x-5'>
          <div className='w-2/5 h-fit sticky top-1/2'>
            <h2>작게, 더 심플하게</h2>
            <p>
              컴팩트한 사이즈로 협소한 매장공간 어디에든 다양하게 배치
              가능합니다.
            </p>
          </div>
          <div className='flex-1 bg-blue-300 h-[100rem]'>img</div>
        </div>

        <div>
          <h2 {...useScroll('', 'up', 1, 0)}>
            재고는 넉넉하게, 종류는 다양하게
          </h2>
          <p>
            48칸의 슬롯, 총 144갑의 담배 적재가 가능하며 유통되고 있는 모든
            유형의 담배(기본형, 슬림형, 전자담배)를 토출 할 수 있습니다.
          </p>

          <div>
            <img src='' alt='1' />
            <img src='' alt='2' />
            <img src='' alt='3' />
          </div>
        </div>

        <div className='w-full flex gap-x-5'>
          <div className='flex-1 bg-blue-300 h-[100rem]'>img</div>
          <div className='w-2/5 h-fit sticky top-1/2'>
            <h2>동작은 효율적으로, 오류는 더 적게</h2>
            <p>
              우노스의 기술력으로 만들어진 호퍼는 단 2개의 모듈만으로 제품을
              토출시킵니다. 타사대비 압도적으로 적은 숫자의 모듈 사용은
              오류동작을 최소화 시킵니다.
            </p>
          </div>
        </div>

        <div>
          <h2>광고는 임팩트 있게, 효과적으로</h2>
          <p>
            토출기 상단 모니터를 통해 다양한 광고 송출이 가능합니다. 모니터
            하단에 토출구가 배치되어 고객들은 모니터에 시선을 둘 수 밖에
            없습니다.
          </p>

          <div>
            <img src='' alt='1' />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Main;
