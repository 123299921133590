import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div(({ isTop }) => [
  tw`w-full flex fixed top-0 left-0 justify-between items-center h-[6rem]`,
  isTop ? tw`backdrop-blur-none` : tw`backdrop-blur-sm`,
]);

const Header = () => {
  const [isTop, setIsTop] = useState(true);

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    scrollPosition < window.innerHeight ? setIsTop(true) : setIsTop(false);
  };

  useEffect(() => {
    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container isTop={isTop}>
      <div>
        logo img
        <span className='text-unos-navy text-xl font-bold'>담배 자판기</span>
      </div>

      <div className='text-base'>
        <ul className='flex text-lg'>
          <li>제품소개(LINK)</li>
          <li>활용사례</li>
          <li>FAQ</li>
        </ul>
      </div>

      <div className='flex'>
        <button>스마트 스토어</button>
        <div>상담신청</div>
      </div>
    </Container>
  );
};

export default Header;
