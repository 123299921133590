import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/layout/layout';
import Main from './pages/main/main';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Main />}></Route>
          {/* <Route></Route> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
