import React, { useEffect } from 'react';

import Header from '../header/header';
import { Outlet, useLocation } from 'react-router-dom';

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    //let { pathname } = location;

    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {/* <Header /> */}
      <main className='w-full min-h-screen'>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
